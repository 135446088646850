import { ActionList } from '@sqs/rosetta-compositions';
import { BackButton, Divider, Stack } from '@sqs/rosetta-elements';
import { Cell, ColumnDef, Table, TableGridRecordProps } from '@sqs/rosetta-experimental';
import { Ellipses } from '@sqs/rosetta-icons';
import { Box, Button, Flex, Text } from '@sqs/rosetta-primitives';
import { useTheme } from '@sqs/rosetta-styled';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchUserSchedulingInstances, getUserPermissionsForSchedulingInstance } from '../../apis/EnterpriseApiV2';
import { PAGINATION_LIMIT_MIN } from '../../const/pagination';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { T, t } from '../../i18n';
import { SchedulingInstance } from '../../models/SchedulingInstance';
import { ORG_MANAGER, ORG_MEMBER, SqspUser, sqspUserRoleMap } from '../../models/SqspUser';
import { selectEnterprise } from '../../stores/currentUser';
import { fetchOrgUser, filterSingleUser, selectAll } from '../../stores/orgUsers';
import { RootState } from '../../stores/rootReducer';
import { fetchSchedulingInstances } from '../../stores/schedulingInstances';
import { useAppDispatch, useAppSelector } from '../../stores/store';
import { isPendingInvitationStatus } from '../../utils/sqspUserUtils';
import { AcuityLoader } from '../common/AcuityLoader';
import { EmptyResults } from '../common/EmptyResults';
import { PermissionsMap } from '../common/PermissionsMap';
import { TableHeader } from '../common/Table/TableHeader';
import { TablePagination } from '../common/Table/TablePagination';
import { UserStatusChip } from '../common/UserStatusChip';
import { UserResendInvitationActionItem } from './UserResendInvitationActionItem';
import { useUserActionModals } from './useUserActionModals';
import { userImage } from './util';

export const UserView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMobile } = usePlatformBreakpoint();
  const { colors, borders, space } = useTheme();
  const { id } = params;
  const userId = parseInt(id || '', 10);
  const [userSchedulingInstances, setUserSchedulingInstances] = useState<SchedulingInstance[] | null>(null);
  const [loadingChangePermission, setLoadingChangePermission] = useState<boolean>(false);

  const { currentUser, enterpriseId, orgUser, isLoading, hasLoadedSchedulingInstances, schedulingInstances } =
  useAppSelector((state: RootState) => {
    return {
      currentUser: state.currentUser.currentUser as SqspUser,
      enterpriseId: selectEnterprise(state.currentUser)?.id,
      orgUser: filterSingleUser(state, userId),
      isLoading: state.orgUsers.isLoading || state.currentUser.isLoading,
      hasLoadedSchedulingInstances: !state.schedulingInstances.isLoading,
      schedulingInstances: selectAll(state.schedulingInstances)
    };
  });

  const {
    renderModals,
    showRemoveUserFromOrgModal,
    showAddToSchedulingInstanceModal,
    showChangeRoleModal,
    showRemoveUserFromSchedulingInstanceModal,
    showChangeUserPermissionModal
  } = useUserActionModals(userId, undefined, () => {
    if (enterpriseId) {
      dispatch(fetchOrgUser(enterpriseId, userId));
      setUserSchedulingInstances(null);
    }
  });

  useEffect(() => {
    if (enterpriseId && userId && !orgUser) {
      dispatch(fetchOrgUser(enterpriseId, userId));
    }
  }, [enterpriseId, userId, orgUser]);

  useEffect(() => {
    if (enterpriseId && userId > 0 && !hasLoadedSchedulingInstances) {
      dispatch(fetchSchedulingInstances(enterpriseId));
    }
  }, [enterpriseId, userId, hasLoadedSchedulingInstances]);

  useEffect(() => {
    if (!userSchedulingInstances && enterpriseId && userId > 0) {
      fetchUserSchedulingInstances(enterpriseId, userId).then((res) => {
        setUserSchedulingInstances(res.data);
      });
    }
  }, [userSchedulingInstances, enterpriseId, userId]);

  const renderSchedulingInstances = useMemo(() => {
    if (isPendingInvitationStatus(orgUser?.status)) {
      return (
        <Box mx={isMobile ? 3 : 6}>
          <EmptyResults
            noImage
            title={t("This user hasn\u2019t accepted their invitation yet.", {}, { project: 'enterprise-dashboard' })}
            subtitle={t("Once a user accepts their invitation to the organization, you will be able to add them to a Scheduling Instance.",

            {}, {
              project: 'enterprise-dashboard' })
            } />

        </Box>);

    }

    if (!hasLoadedSchedulingInstances || userSchedulingInstances === null || schedulingInstances === null) {
      return (
        <Flex
          mx={isMobile ? 3 : 6}
          border={`${borders[1]} ${colors.gray[700]}`}
          height="20vh"
          alignItems="center"
          justifyContent="center">

          <AcuityLoader />
        </Flex>);

    }

    if (schedulingInstances.length === 0) {
      return (
        <EmptyResults
          title={t("You don't have any Scheduling Instances for your org yet.",

          {}, {
            project: 'enterprise-dashboard' })
          }
          subtitle={t("Create a Scheduling Instance from your Dashboard so that you can give users access to it.",

          {}, {
            project: 'enterprise-dashboard' })
          }
          actionButton={
          <Button.Primary type="button" size="medium" width={isMobile ? '100%' : ''} onClick={() => navigate('/')}>
              <T project="enterprise-dashboard">{"Go to Dashboard"}</T>
            </Button.Primary>
          } />);


    }

    if (schedulingInstances.length > 0 && userSchedulingInstances.length === 0) {
      return (
        <Box px={isMobile ? 3 : 6}>
          <EmptyResults
            title={t("{name} doesn't have access to any Scheduling Instances yet.",

            { name: orgUser?.name }, {
              project: 'enterprise-dashboard' })
            }
            subtitle={t("Give this user access to a Scheduling Instance so they can start managing their appointments.",

            {}, {
              project: 'enterprise-dashboard' })
            }
            actionButton={
            <Button.Primary
              type="button"
              size="medium"
              width={isMobile ? '100%' : ''}
              onClick={() => showAddToSchedulingInstanceModal(userId)}>

                <T project="enterprise-dashboard">{"Add to a Scheduling Instance"}</T>
              </Button.Primary>
            } />

          {renderModals()}
        </Box>);

    }

    const tableColumns: ColumnDef<SchedulingInstance, any>[] = [
    {
      accessorKey: 'instanceName',
      header: ({ column }: any) =>
      <TableHeader label={t("Name", null, { project: 'enterprise-dashboard' })} column={column} />

    }];

    if (currentUser.role === ORG_MANAGER && orgUser?.role === ORG_MEMBER) {
      tableColumns.push(
        {
          accessorKey: 'permissions',
          enableSorting: false,
          header: ({ column }) =>
          <TableHeader
            label={t("Permissions", null, { project: 'enterprise-dashboard' })}
            column={column}
            tooltip={t("The permissions this user has within each Scheduling Instance.",

            {}, {
              project: 'enterprise-dashboard' })
            } />,


          cell: (cell) => <PermissionsMap permission={cell.getValue()} />
        },
        {
          accessorKey: 'id',
          enableSorting: false,
          header: ({ column }) =>
          <TableHeader label={t("Actions", null, { project: 'enterprise-dashboard' })} column={column} />,

          cell: (cell) => {
            const ariaLabel = t("Open scheduling instance actions", {}, { project: 'enterprise-dashboard' });
            const menuItems = ({ onRequestClose }: {onRequestClose: () => void;}) =>
            <Stack
              bg="white"
              direction="column"
              py={isMobile ? 0 : 1}
              divider={isMobile && <Divider as="div" role="presentation" />}>

                <ActionList.Item
                as="div"
                onClick={async () => {
                  if (enterpriseId) {
                    setLoadingChangePermission(true);
                    const resp = await getUserPermissionsForSchedulingInstance(
                      enterpriseId,
                      cell.row.original.id,
                      orgUser.id
                    );
                    setLoadingChangePermission(false);
                    showChangeUserPermissionModal(cell.row.original.id, resp);
                  }
                }}>

                  <Flex justifyContent="space-between" aria-live="polite">
                    <T project="enterprise-dashboard">{"Change user permission"}</T>
                    {loadingChangePermission &&
                  <Box pl={2} pt={2}>
                        <AcuityLoader size="sm" />
                      </Box>
                  }
                  </Flex>
                </ActionList.Item>
                <ActionList.Item
                as="div"
                onClick={() => showRemoveUserFromSchedulingInstanceModal(cell.row.original.id)}
                color={colors.red[400]}>

                  <T project="enterprise-dashboard">{"Remove Scheduling Instance access"}</T>
                </ActionList.Item>
              </Stack>;

            if (isMobile) {
              return (
                <ActionList.Sheet
                  renderHeader={() =>
                  <Text.Body textAlign="center" fontWeight={500} pt={3}>
                      {orgUser?.name}
                    </Text.Body>
                  }
                  aria-label={ariaLabel}>

                  {menuItems}
                </ActionList.Sheet>);

            }

            return (
              <ActionList.PopOver aria-label={ariaLabel} anchorPoint={{ x: 'center', y: 'top' }} position="bottom">
                {menuItems}
              </ActionList.PopOver>);

          }
        }
      );
    }

    return (
      <Box px={isMobile ? 3 : 4}>
        <Table
          columns={tableColumns}
          data={userSchedulingInstances}
          enableSort
          enablePagination
          initialState={{
            sorting: [
            {
              id: 'instanceName',
              desc: false
            }],

            pagination: {
              pageIndex: 0,
              pageSize: PAGINATION_LIMIT_MIN
            }
          }}>

          {!isMobile &&
          <>
              <Table.List />
            </>
          }
          {isMobile &&
          <Table.Grid
            sx={{
              gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))',
              marginTop: 3
            }}
            renderRecord={({ record }: TableGridRecordProps<SchedulingInstance>) => {
              if (!record) {
                return null;
              }

              const cells = Object.fromEntries(
                record.getVisibleCells().map((cell: Cell<SchedulingInstance, any>) => [cell.column.id, cell])
              );
              return (
                <Table.Grid.Record>
                    <Flex>
                      <Flex flexDirection="column" p={3} gap={0.5} width="100%">
                        <Flex flexDirection="column" mb={1}>
                          <Flex justifyContent="space-between">
                            <Table.Grid.Record.Cell sx={{ fontWeight: 500 }} cell={cells.instanceName} />
                            <Table.Grid.Record.Cell cell={cells.id} />
                          </Flex>
                        </Flex>
                        {orgUser?.role !== ORG_MANAGER &&
                      <Flex gap={2} alignItems="center">
                            <Text.Caption fontSize={3}>
                              <T project="enterprise-dashboard">{"Permissions"}</T>
                            </Text.Caption>
                            <Table.Grid.Record.Cell cell={cells.permissions} />
                          </Flex>
                      }
                      </Flex>
                    </Flex>
                  </Table.Grid.Record>);

            }} />

          }
          <TablePagination />
        </Table>
      </Box>);

  }, [userSchedulingInstances, schedulingInstances]);

  if (isLoading || !enterpriseId) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <AcuityLoader />
      </Flex>);

  }

  if (!userId || currentUser.id === orgUser?.id) {
    navigate('/users');
  }

  if (!orgUser) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Text.Title>
          <T project="enterprise-dashboard">{"User Not Found"}</T>
        </Text.Title>
      </Flex>);

  }

  const actionMenu = () => {
    const ariaLabel = t("Open User Actions", {}, { project: 'enterprise-dashboard' });
    const menuItems = ({ onRequestClose }: {onRequestClose: () => void;}) => {
      if (!id || !enterpriseId) {
        return <></>;
      }
      return (
        <Stack direction="column" py={1} divider={isMobile && <Divider as="div" role="presentation" />}>
          {isPendingInvitationStatus(orgUser.status) ?
          <UserResendInvitationActionItem
            inviteCode={orgUser.inviteCode}
            enterpriseId={enterpriseId}
            onRequestClose={onRequestClose} /> :


          <>
              <ActionList.Item as="div" onClick={() => showChangeRoleModal()}>
                <T project="enterprise-dashboard">{"Change role"}</T>
              </ActionList.Item>
              {orgUser.role === ORG_MEMBER &&
            <ActionList.Item as="div" onClick={() => showAddToSchedulingInstanceModal()}>
                  <T project="enterprise-dashboard">{"Add user to a Scheduling Instance"}</T>
                </ActionList.Item>
            }
            </>
          }
          <ActionList.Item as="div" onClick={() => showRemoveUserFromOrgModal(orgUser)} color="red.400">
            {isPendingInvitationStatus(orgUser.status) ? <T
              project="enterprise-dashboard">{"Cancel invitation"}</T> : <T

              project="enterprise-dashboard">{"Remove user from org"}</T>
            }
          </ActionList.Item>
        </Stack>);

    };

    if (isMobile) {
      return (
        <Flex ml={0} alignItems="center" justifyContent="center">
          <Flex justifyContent="flex-end" alignItems="center" height="100%">
            <ActionList.Sheet
              renderHeader={() =>
              <Text.Body textAlign="center" fontWeight={500} pt={3}>
                  {orgUser.name}
                </Text.Body>
              }
              aria-label={ariaLabel}
              renderTrigger={({ toggleActionListOpen }) =>
              <Button.Tertiary onClick={toggleActionListOpen} aria-label={ariaLabel}>
                  <Ellipses />
                </Button.Tertiary>
              }>

              {menuItems}
            </ActionList.Sheet>
          </Flex>
        </Flex>);

    }

    return (
      <Flex ml={2} alignItems="center">
        <Flex justifyContent="flex-end" alignItems="center" height="100%">
          <ActionList.PopOver
            aria-label={ariaLabel}
            renderTrigger={({ toggleActionListOpen }) =>
            <Button.Secondary size="large" onClick={toggleActionListOpen} aria-label={ariaLabel}>
                <Flex alignItems="center" gap={1}>
                  {<T project="enterprise-dashboard">{"Actions"}</T>}
                  <Ellipses />
                </Flex>
              </Button.Secondary>
            }
            anchorPoint={{ x: 'left' }}
            position="bottom">

            {menuItems}
          </ActionList.PopOver>
        </Flex>
      </Flex>);

  };

  return (
    <Box>
      <BackButton
        mt={isMobile ? 3 : 6}
        mb={isMobile ? 1 : 3}
        onClick={() => navigate('/users')}
        label={t("Users", {}, { project: 'enterprise-dashboard' })} />

      <Flex
        border={`${borders[1]} ${colors.gray['700']}`}
        padding={isMobile ? 4 : 7}
        flexDirection={isMobile ? 'column' : 'row'}>

        {!isMobile && <Flex mr={2}>{userImage(orgUser.name, orgUser.image)}</Flex>}
        <Flex mx={isMobile ? 0 : 4} flexGrow="1">
          <Box width={'100%'}>
            {isMobile ?
            <Flex gap={1} justifyContent="space-between" alignItems="center">
                <Text.Body fontWeight={500} m={0}>
                  {orgUser.name}
                </Text.Body>
                {currentUser.id !== orgUser.id && actionMenu()}
              </Flex> :

            <Text.Title m={0}>{orgUser.name}</Text.Title>
            }
            <Stack
              direction={isMobile ? 'column' : 'row'}
              justifyContent="flex-start"
              alignItems={isMobile ? '' : 'center'}
              mt={space[2]}
              gap={isMobile ? 0 : 10}>

              <Flex alignItems="center" gap={2}>
                <Text.Body my={isMobile ? 0 : 1} color={colors.gray[400]}>
                  <T project="enterprise-dashboard">{"Email"}</T>
                </Text.Body>
                <Text.Body my={isMobile ? 0 : 1} fontWeight={isMobile ? '' : 500}>
                  {orgUser.email}
                </Text.Body>
              </Flex>
              <Flex alignItems="center" gap={2}>
                <Text.Body my={isMobile ? 0 : 1} color={colors.gray[400]}>
                  <T project="enterprise-dashboard">{"Role"}</T>
                </Text.Body>
                <Text.Body my={isMobile ? 0 : 1} fontWeight={isMobile ? '' : 500}>
                  {sqspUserRoleMap(orgUser.role)}
                </Text.Body>
              </Flex>

              <Flex alignItems="center" gap={2}>
                <Text.Body my={isMobile ? 0 : 1} color={colors.gray[400]}>
                  <T project="enterprise-dashboard">{"Status"}</T>
                </Text.Body>
                <Box>
                  <UserStatusChip status={orgUser.status} />
                </Box>
              </Flex>
            </Stack>
          </Box>
        </Flex>
        {!isMobile && currentUser.id !== orgUser.id && actionMenu()}
      </Flex>
      <Text.Subtitle>
        <T project="enterprise-dashboard">{"Scheduling Instances"}</T>
      </Text.Subtitle>
      <Box mx={!isMobile ? -6 : -3} mb={4} position="relative">
        {renderSchedulingInstances}
      </Box>
      {renderModals()}
    </Box>);

};